import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Tile from "./partials/Tile";
import surgery from "../../assets/images/tiles/surgery.svg";
import knee from "./../../assets/images/tiles/knee.svg";
import sports from "./../../assets/images/tiles/sports.svg";
import pain from "./../../assets/images/tiles/pain.svg";
import arthritis from "./../../assets/images/tiles/arthritis.svg";
import work from "./../../assets/images/tiles/work.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const HowCanItHelp = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "What can physiotherapy help with?",
    paragraph:
      "While not an extensive list, below are the most common areas which we can help with.",
  };

  const tiles = [
    {
      title: "Surgery/Fracture Rehabilitation",
      summary:
        "We can help you with your recovery from surgery, whether it be a knee replacement, hip replacement, or any other surgery.",
      image: surgery,
    },
    {
      title: "Replacement Hip and Knee care",
      summary:
        "We understand that you may be nervous about your new hip or knee. We can help you with your recovery and get you back to your normal activities.",
      image: knee,
    },
    {
      title: "Sports injuries",
      summary:
        "We love sports and we love helping people get back to their sport. We can help you recovery from any injury and get you playing again.",
      image: sports,
    },
    {
      title: "Aches & Pains",
      summary:
        "Ligament, muscle, strains & tendon injuries can really affect your quality of life. With our help, we can help address the root cause of your pain.",
      image: pain,
    },
    {
      title: "Arthritis",
      summary:
        "Arthritis can be a very painful condition. With the right treatment, we can help you manage your pain and improve your quality of life.",
      image: arthritis,
    },
    {
      title: "Work Related Injuries",
      summary:
        "An injury at work can occur like any other. But we understand how it can be even more stressful. It's why believe in treating the person and not only their symptoms.",
      image: work,
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {tiles.map(({ title, summary, image }) => (
              <Tile title={title} description={summary} image={image} key={title.replace(" ", "_")}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

HowCanItHelp.propTypes = propTypes;
HowCanItHelp.defaultProps = defaultProps;

export default HowCanItHelp;
