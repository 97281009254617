import React from "react";
import PropTypes from "prop-types";
import Image from "../../elements/Image";

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

const Tile = ({ title, description, image }) => {
  return (
    <>
      <div className="tiles-item reveal-from-bottom">
        <div className="tiles-item-inner">
          <div className="features-tiles-item-header">
            <div className="features-tiles-item-image mb-16">
              <Image
                src={image}
                alt="Features tile icon 01"
                width={64}
                height={64}
              />
            </div>
          </div>
          <div className="features-tiles-item-content">
            <h4 className="mt-0 mb-8">{title}</h4>
            <p className="m-0 text-sm">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

Tile.propTypes = propTypes;

export default Tile;
