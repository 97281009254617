import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Pricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const sectionHeader = {
    title: "Pricing",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-reveal-delay="200"
          />
          <table className="ta-l">
          <thead>
            <tr>
              <th>Service</th>
              <th>Price</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <b>Initial Assessment</b>
              </td>
              <td>
                <b>£65</b>
              </td>
            </tr>
            <tr>
              <td>Follow Up (30min)</td>
              <td>£40</td>
            </tr>
            <tr>
              <td>Follow Up (45min)</td>
              <td>£50</td>
            </tr>
            <tr>
              <td>Follow Up (60min)</td>
              <td>£60</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
