import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { MapView } from "@aws-amplify/ui-react-geo";
import { Source, Layer } from "react-map-gl";
import workingArea from "../../assets/maps/workingArea.json";
import { ErrorBoundary } from "../elements/ErrorBoundary";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Area = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("", pushLeft && "push-left");

  const sectionHeader = {
    title: "Where we operate",
    paragraph:
      "The map shows the area where we can come visit you in the comfort of your own home.",
  };
  const operatingArea = {
    id: "polygons",
    type: "fill",
    source: "polygons-source",
    paint: {
      "fill-color": "#AE9567",
      "fill-opacity": 0.3,
    },
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <ErrorBoundary errorMessage={"Failed to load map"}>
                  <MapView
                    initialViewState={{
                      latitude: 52.05730123225601,
                      longitude: 1.1449399288074464,
                      zoom: 8,
                      maxZoom: 9,
                      minZoom: 8,
                    }}
                    style={{ width: "100%", height: "65vh" }}
                    id="map"
                  >
                    <Source
                      id="polygons-source"
                      type="geojson"
                      data={workingArea}
                    >
                      <Layer {...operatingArea} />
                    </Source>
                  </MapView>
                </ErrorBoundary>
              </div>
            </div>
          </div>
          <p className="h4">We operate the in following towns / cities:</p>
          <ul>
            <li>Ipswich</li>
            <li>Woodbridge</li>
            <li>Felixstowe</li>
            <li>Colchester</li>
            <li>Stowmarket</li>
            <li>Needham Market</li>
            <li>Claydon</li>
            <li>Manningtree</li>
            </ul>
            <small><i>If you cannot see your location on here but you know you aren't too far away from one listed give us a ring to see if you are within traveling distance</i></small>
        </div>
      </div>
    </section>
  );
};

Area.propTypes = propTypes;
Area.defaultProps = defaultProps;

export default Area;
