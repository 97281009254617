import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Title = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-8 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Welcome to <span className="text-color-primary">A.M.N</span>{" "}
              Physiotherapy
            </h1>
            <div className="container">
              <h5
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                The Preferred Healthcare Provider at Your Doorstep.
              </h5>
              <div className="reveal-from-bottom" data-reveal-delay="500">
                <p>
                  Here at AMN Physiotherapy, your speedy recovery is our
                  priority. Our holistic approach helps patients achieve
                  long-term wellness and improve physical health and
                  performance.
                </p>
              </div>
              <div className="reveal-from-bottom" data-reveal-delay="650">
                <p>
                  We listen to your story, take time to understand your concern
                  and take a detailed note of these along with your
                  expectations, our aim is to empower you to take back control
                  of your situation.
                </p>
                <p>
                  From there we create an individualized treatment plan aimed at
                  achieving your goals to better your Quality of Life.
                </p>
              </div>
            </div>
            {/* <div className="container-sm">
              <div
                className="hero-figure reveal-from-bottom illustration-element-01"
                data-reveal-value="20px"
                data-reveal-delay="800"
              >
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/amn-hero-no-bg.png")}
                  alt="Hero"
                  height={250}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
