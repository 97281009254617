import React from "react";
// import sections
import Title from "../components/sections/TitleSection";
import AboutUs from "../components/sections/AboutUs";
import HowCanItHelp from "../components/sections/HowCanItHelp";
import OurServices from "../components/sections/OurServices";
import Area from "../components/sections/Area";
import QuickButtons from "../components/sections/QuickButtons";
import Pricing from "../components/sections/Pricing";

const Home = () => {
  return (
    <>
      <Title className="illustration-section-01" />
      <QuickButtons />
      <AboutUs topDivider />
      <HowCanItHelp />
      <OurServices topDivider />
      <Area topDivider />
      <Pricing topDivider />
    </>
  );
};

export default Home;
