import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Accordion from "../elements/Accordion";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const OurServices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const sectionHeader = {
    title: "Our Services",
  };

  const accordionData = [
    {
      title: "Joint Replacement Physiotherapy",
      content: [
        "It's easy to forget that a joint replacement is a major operation, usually only offered to people as a last resort when other treatments haven't worked.",
        "The chances of making a full recovery and living a pain-free life afterwards are high.",
        "A crucial part of successful rehabilitation is physiotherapy this includes evidence based, progressive, exercise focused plans catering to your specific goals without the need for expensive kit",
        "We are here to help you optimise mobility pre-op and continue your rehabilitation journey post-op, alleviate pain and get back to the activities that you love.  ",
      ],
    },
    {
      title: "Sports Massage",
      content: [
        "A Sports Massage will help iron out pains and strains, decrease blood pressure, promote sporting performance and even improve sleep patterns.",
        "Whether you're considering a massage to improve post-exercise healing, or would like to relieve the stresses and strains of modern life.",
        "Whilst sports massage is often used to help athletes and active sportspeople to perform at their best, it's also beneficial for those who experience discomfort and tension in their bodies caused by everyday stresses.",
      ],
    },
    {
      title: "Sports Injuries",
      content: [
        "We take the time to understand the root of your problem to provide the best and most effective treatment. With cutting-edge equipment and dedicated care, you'll be taking your first steps towards your recovery.",
        "Sports injury rehabilitation is a major field of care covered by physiotherapy and at AMN Physiotherapy we believe we are one of the best.",
        "We understand the delicate balance between wanting to return to sports quickly and ensuring you make the best possible recovery. We will use dedicated sports therapy assessment and treatment techniques, including comprehensive biomechanical analysis.",
        "Whether you're a fully-fledged athlete or just someone who runs every now and again, we can treat you effectively and advise you on avoiding future injuries.",
        "It's a common misconception that in order to see a sports physio, you have to have a sports injury. Whilst sports physiotherapy is perfect for easing injury and helping bounce back after hurting themselves, sports physiotherapy is injury preventative too.",
      ],
    },
    {
      title: "Back & Neck Pain",
      content: [
        "Here at AMN Physiotherapy, we focus on restoring movement and function to your whole back after you've been affected by Back and Neck injury. We look at how the nerves, muscles, and bones in your back and neck are affected, and how treatment with exercise therapy and manual therapies can help. We encourage you to take an active part in your rehabilitation.",
        "We will explain the treatment we recommend, and how we expect this to help your back pain.",
        "Even if you've had back pain for some time, an exercise programme with a physiotherapist can provide relief and get you moving again. Manual therapies such as manipulation and mobilisation have been shown to be helpful too.",
        "The pain relief and improvement in functionality you get with physiotherapy can last long enough for you to start getting back to normal activities. Keeping active is the best thing for back pain. It can get you back to work faster, you're less likely to have long-term problems, and you're less likely to get back pain again.",
      ],
    },
    {
      title: "Aches & Pains: Ligament, muscle, strains & tendon problems",
      content: [
        "We realise that many conditions and symptoms are difficult to diagnose and classify.",
        "This could come under the banner of 'Aches & Pains' and we are very happy to help.",
        "We recommend a full assessment of your movement, strength and muscle balance can provide clues as to why you're suffering from these symptoms and we can advise you on management techniques such as dealing with such symptoms during exercise or at work and also corrective strategies and exercises to improve and even banish them where possible.",
        "Early management of these problems can also prevent more complex injuries or symptoms further down the line. People often report the slow build-up of symptoms that they think will 'go away by themselves' but then find, as time goes on, that they can get worse or more intrusive or indeed change to a related, but different symptom somewhere else.",
      ],
    },
    {
      title: "Arthritis & other joint conditions",
      content: [
        "It's important that you try to keep active when you have arthritis. Many people worry that exercise will increase their pain or damage their joints. But joints are designed to move, and inactivity weakens the muscles.",
        "We will ask you about your current level of activity and any particular problems you're having and also examine your joints to assess your muscle strength and the range of movement in your joints. This will help us tailor a programme of treatments, exercises, and activities to meet your individual needs.",
      ],
    },
    {
      title: "Hydrotherapy",
      content: [
        "Hydrotherapy is a physiotherapy treatment that takes place in a heated pool. The warm temperature of the water helps to relax muscles and ease pain, while the buoyancy of the water can make it easier to move.",
        "We are able to assist in this service, but a local pool will need to be booked and paid for by the client. Please contact to see if we are able to assist in finding a suitable pool.",
      ],
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-reveal-delay="200"
          />
          <div className="accordion">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} key={title.replace(" ", "_")}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

OurServices.propTypes = propTypes;
OurServices.defaultProps = defaultProps;

export default OurServices;
