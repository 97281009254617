import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SectionHeader from "../sections/partials/SectionHeader";

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
};

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
};

const BookingModal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  ...props
}) => {
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", stopProgagation);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", stopProgagation);
    };
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]);

  const handleBodyClass = () => {
    if (document.querySelectorAll(".modal.is-active").length) {
      document.body.classList.add("modal-is-active");
    } else {
      document.body.classList.remove("modal-is-active");
    }
  };

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  };

  const stopProgagation = (e) => {
    e.stopPropagation();
  };

  const classes = classNames(
    "modal",
    show && "is-active",
    className
  );
  const sectionHeader = {
    title: "Book a Consultation",
    paragraph: "Let us know who you are and we will be in contact right away.",
  };
  return (
    <>
      {show && (
        <div {...props} className={classes} onClick={handleClose}>
          <div className="modal-inner" onClick={stopProgagation}>
            {/* <div className="responsive-video"> */}
              {!closeHidden && (
                <button
                  className="modal-close"
                  aria-label="close"
                  onClick={handleClose}
                ></button>
              )}
              <div className="modal-content">
                <div>
                <SectionHeader
                  data={sectionHeader}
                  className="center-content"
                />
                <p>Call: <a href="tel:07716351867"><u>07716351867</u></a></p>
                <p>Email: <a href="&#109;a&#105;l&#116;&#111;:&#97;&#108;&#97;&#110;&#64;&#97;&#109;&#110;&#45;&#112;&#104;&#121;&#115;&#105;&#111;&#116;&#104;&#101;&#114;&#97;&#112;&#121;&#46;&#99;&#111;&#109;">Click / Tap to Open Email</a></p>
                </div>
                {/* <Input
                  id="booking-first-name"
                  type="text"
                  label="First Name"
                  placeholder="Just a first name is fine"
                />
                <Input
                  id="booking-telephone"
                  type="tel"
                  label="Telephone Number (mobile or landline)"
                  placeholder="A number we can reach you at (mobile or landline)"
                />
                <Input
                  id="booking-email"
                  type="email"
                  label="Email Address"
                  placeholder="Email (promise no spam)"
                />
                <hr />
                <Select
                  placeholder={"Contact Preference"}
                  label={"Contact Preference"}
                >
                  <option value="0">I Don't Mind (Either)</option>
                  <option value="1">I would prefer telephone</option>
                  <option value="2">I would prefer email</option>
                </Select>
                <hr />
                <Button color={"primary"}>Confirm</Button> */}
              </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

BookingModal.propTypes = propTypes;
BookingModal.defaultProps = defaultProps;

export default BookingModal;
