import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import BookingModal from "../elements/BookingModal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const QuickButtons = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClasses = classNames(
    "testimonial-inner section-top",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );
  const [bookingModalOpen, setBookingModalOpen] = useState(false);

  const handleClickScroll = () => {
    const element = document.getElementById("section-about-us");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <ButtonGroup>
            <Button
              color="primary"
              className={"reveal-from-left"}
              data-reveal-delay="750"
              onClick={handleClickScroll}
            >
              Learn about us
            </Button>
            {/* <Button
              color="primary"
              className={"reveal-from-left"}
              data-reveal-delay="750"
              onClick={handleClickScroll}
            >
              Meet the team
            </Button> */}
            <Button
              color="primary"
              className={"reveal-from-right"}
              data-reveal-delay="750"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setBookingModalOpen(true);
              }}
            >
              Book a Consultation With Us
            </Button>
          </ButtonGroup>
          {/* <ButtonGroup>
            <Button
              color="primary"
              className={"reveal-from-right"}
              data-reveal-delay="750"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setBookingModalOpen(true);
              }}
            >
              Book a Consultation With Us
            </Button>
          </ButtonGroup> */}
        </div>
      </div>
      <BookingModal
        id="modal"
        ariaLabel="modal-headline"
        show={bookingModalOpen}
        handleClose={() => setBookingModalOpen(false)}
      ></BookingModal>
    </section>
  );
};

QuickButtons.propTypes = propTypes;
QuickButtons.defaultProps = defaultProps;

export default QuickButtons;
