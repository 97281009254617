import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const AboutUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const sectionHeader = {
    title: "About Us",
    paragraph: "Who we are and what we do",
  };

  return (
    <section {...props} className={outerClasses} id="section-about-us">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-top"
            data-reveal-delay="200"
          />
          <div className="reveal-from-top">
            <p>
              We believe in treating the person and not only their symptoms. We
              aim to treat the whole body rather than treating an injury or
              medical issue in isolation.
            </p>
            <p>
              That's why we offer an extensive range of services to help improve
              movement and function.
            </p>
          </div>
          <div className="reveal-from-bottom" data-reveal-delay="250">
            <p>
              At AMN Physiotherapy we have in-depth knowledge of musculoskeletal
              diagnosis and their management, we apply principles & practices of
              rehabilitation and ergonomics with advanced clinical reasoning
              based on evidence-based practice.
            </p>
            <p>
              Our specialists have a detailed understanding of anatomy and
              physiology relevant to musculoskeletal conditions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;
